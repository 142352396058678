import { Form, Input } from "antd";
import React, { useEffect } from "react";

const { TextArea } = Input;

const NoteForm = ({
  form,
  setForm,
  setIsDisabled,
  visionItem,
  handleSubmit,
}) => {
  const handleValuesChange = (changedValues) => {
    const note = changedValues.note;
    setIsDisabled(!note);
  };

  useEffect(() => {
    if (visionItem) {
      form.setFieldsValue({
        note: visionItem.note,
      });
    } else {
      form.setFieldsValue({
        note: "",
      });
    }
  }, [visionItem, form]);

  return (
    <Form
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleSubmit();
        }
      }}
      form={form}
      layout="vertical"
      name="form_in_modal"
      initialValues={{
        note: visionItem?.note,
      }}
      onValuesChange={handleValuesChange}
    >
      <Form.Item
        name="note"
        label="Note"
        rules={[
          {
            required: true,
            message: "Please enter a note",
          },
        ]}
      >
        <TextArea
          rows={4}
          placeholder="Write something cool here..."
          maxLength={500}
        />
      </Form.Item>
    </Form>
  );
};

export default NoteForm;
