import { Button, Form, Input, Modal, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import { createVisionBoard, updateVisionBoard } from "vision/visionSlice";
import useToast from "hooks/useToast";
import LErrorMessages from "components/LErrorMessages/LErrorMessages";
import ReactQuill from "react-quill";
import { isEmpty } from "lodash";
import LBox from "components/LBox/LBox";
import { updateGratitudeEntry } from "gratitude/gratitudeSlice";

const UpdateGratitudeEntryModal = ({ gratitudeEntry }) => {
  const prompts = useSelector((state) => state.gratitude.prompts);
  const { toastError, toastSuccess } = useToast();
  const [entry, setEntry] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [prompt, setPrompt] = useState("");
  const updateGratitudeEntryModalIsOpen = useSelector(
    (state) => state.modals.updateGratitudeEntryModalIsOpen
  );
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setIsDisabled(true);
    dispatch(toggleModal({ modalName: MODAL_NAMES.UPDATE_GRATITUDE_ENTRY }));
  };

  const handleSubmit = () => {
    const payload = {
      id: gratitudeEntry.id,
      prompt,
      content: entry,
    };

    dispatch(updateGratitudeEntry(payload))
      .unwrap()
      .then(() => {
        handleCloseModal();
        toastSuccess("Gratitude entry updated successfully! 🎉");
      });
  };

  useEffect(() => {
    if (gratitudeEntry) {
      setEntry(gratitudeEntry.content);
      setPrompt(gratitudeEntry.prompt);
    }
  }, [gratitudeEntry]);

  return (
    <Modal
      open={updateGratitudeEntryModalIsOpen}
      title={"Update Gratitude Entry"}
      okText={"Update"}
      cancelText="Cancel"
      onCancel={handleCloseModal}
      onOk={handleSubmit}
      okButtonProps={{
        disabled:
          isEmpty(entry) ||
          (entry === gratitudeEntry?.content &&
            prompt === gratitudeEntry?.prompt),
      }}
      style={{ minWidth: "50%" }}
    >
      <LBox mb8>
        <Select
          style={{ width: "50%" }}
          defaultValue={gratitudeEntry?.prompt}
          onChange={(value) => setPrompt(value)}
        >
          {prompts.map((p) => (
            <Option key={p} value={p}>
              {p}
            </Option>
          ))}
        </Select>
      </LBox>
      <ReactQuill
        theme="snow"
        value={entry}
        onChange={setEntry}
        scrollingContainer="html"
        className="custom-quill"
      />
    </Modal>
  );
};
export default UpdateGratitudeEntryModal;
