import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "helpers/axiosInstance";
import axiosI from "helpers/axiosInstance";
import { createCustomQuote, updateCustomQuote } from "quotes/quotesSlice";

const initialState = {
  notificationSettings: null,
};

export const getNotificationData = createAsyncThunk(
  "notifications/getNotificationData",
  async (thunkAPI) => {
    const response = await axiosI.get("/notification_settings");
    return response.data;
  }
);

export const updateNotificationSetting = createAsyncThunk(
  "notifications/updateNotificationSetting",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/notification_settings/${data.id}`,
        { ...data }
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createNotificationSetting = createAsyncThunk(
  "notifications/createNotificationSetting",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post(`/notification_settings`, {
        ...data,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotificationData.fulfilled, (state, action) => {
      state.notificationSettings = action.payload.notification_settings;
    });
    builder.addCase(createNotificationSetting.fulfilled, (state, action) => {
      const { notification_setting } = action.payload;
      state.notificationSettings[notification_setting.id] =
        notification_setting;
    });
    builder.addCase(updateNotificationSetting.fulfilled, (state, action) => {
      const { notification_setting } = action.payload;
      state.notificationSettings[notification_setting.id] =
        notification_setting;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = notificationsSlice.actions;

export default notificationsSlice.reducer;
