import React from "react";
import "components/LText/LText.css";
import "components/spacing.css";
import "components/colors.css";
import "components/font.css";
import { generateCustomComponentClassNames } from "helpers/generalHelpers";

const LText = ({
  children,
  style,
  id,
  className,
  onMouseEnter,
  onMouseLeave,
  onClick,
  ...props
}) => {
  const classNames = generateCustomComponentClassNames(props);

  return (
    <p
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={`LText ${classNames} ${className}`}
      style={style}
      id={id}
    >
      {children}
    </p>
  );
};

export default LText;
