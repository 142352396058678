export const ROUTE_MAP = {
  vision: {
    path: "/vision",
    label: "Vision",
    name: "vision",
  },
  journal: {
    path: "/journal",
    label: "Journal",
    name: "journal",
  },
  gratitude: {
    path: "/gratitude",
    label: "Gratitude",
    name: "gratitude",
  },
  quotes: {
    path: "/quotes",
    label: "quotes",
    name: "quotes",
  },
  support: {
    path: "/support",
    label: "Support",
    name: "support",
  },
};

export const VISION_ITEM_TYPES = {
  YOUTUBE: "YOUTUBE",
  IMAGE: "IMAGE",
  NOTE: "NOTE",
};

export const FEATURE_NAMES = {
  unlimited_vision_boards: "unlimited_vision_boards",
  unlimited_image_vision_items: "unlimited_image_vision_items",
  unlimited_gratitude_entries: "unlimited_gratitude_entries",
  unlimited_journal_entries: "unlimited_journal_entries",
  unlimited_custom_quotes: "unlimited_custom_quotes",
  email_notifications: "email_notifications",
};
