import { Form, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import YoutubeForm from "modals/CreateOrUpdateVisionItemModal/YoutubeForm";
import { createVisionItem, updateVisionItem } from "vision/visionSlice";
import useToast from "hooks/useToast";
import { VISION_ITEM_TYPES } from "constants";
import LErrorMessages from "components/LErrorMessages/LErrorMessages";
import { selectActiveVisionBoardId } from "vision/selectors";
import ImageForm from "modals/CreateOrUpdateVisionItemModal/ImageForm";
import NoteForm from "modals/CreateOrUpdateVisionItemModal/NoteForm";

const COMPONENT_MAP = {
  YOUTUBE: YoutubeForm,
  IMAGE: ImageForm,
  NOTE: NoteForm,
};

const CreateOrUpdateVisionItemModal = ({ type, isCreate, visionItem }) => {
  const activeVisionBoardId = useSelector(selectActiveVisionBoardId);
  const { toastError, toastSuccess } = useToast();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [errors, setErrors] = useState([]);
  const createOrUpdateVisionItemModalIsOpen = useSelector(
    (state) => state.modals.createOrUpdateVisionItemModalIsOpen
  );
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const VisionItemForm = COMPONENT_MAP[type];

  const handleCloseModal = () => {
    form.resetFields();
    setErrors([]);
    setIsDisabled(true);
    dispatch(
      toggleModal({ modalName: MODAL_NAMES.CREATE_OR_UPDATE_VISION_ITEM })
    );
  };

  const handleSubmit = () => {
    setIsCreating(true);
    form.validateFields().then((values) => {
      const action = isCreate ? createVisionItem : updateVisionItem;
      const payload = new FormData(); // Use FormData for handling file uploads

      payload.append("vision_board_id", activeVisionBoardId);

      if (type === VISION_ITEM_TYPES.YOUTUBE) {
        payload.append("youtube_url", values.youtube_url);
      } else if (type === VISION_ITEM_TYPES.IMAGE) {
        const file = values.image[0]?.originFileObj;
        if (file) {
          payload.append("image", file);
        }
      } else if (type === VISION_ITEM_TYPES.NOTE) {
        payload.append("note", values.note);
      }

      if (!isCreate) {
        payload.append("id", visionItem.id);
      }

      dispatch(action(payload))
        .unwrap()
        .then(() => {
          setIsCreating(false);
          handleCloseModal();
          toastSuccess(
            isCreate
              ? "Vision item added successfully! 🎉"
              : "Vision item updated successfully! 🎉"
          );
        })
        .catch(({ errors }) => {
          setIsCreating(false);
          setErrors(errors);
        });
    });
  };

  return (
    <Modal
      open={createOrUpdateVisionItemModalIsOpen}
      title={isCreate ? "Create Vision Item" : "Update Vision Item"}
      okText={isCreate ? "Create" : "Update"}
      cancelText="Cancel"
      onCancel={handleCloseModal}
      onOk={handleSubmit}
      okButtonProps={{ disabled: isDisabled || isCreating }}
    >
      <LErrorMessages errors={errors} />
      <VisionItemForm
        form={form}
        setForm={form}
        setIsDisabled={setIsDisabled}
        visionItem={visionItem}
        handleSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default CreateOrUpdateVisionItemModal;
