export function isEmpty(obj) {
  if (Array.isArray(obj)) {
    if (obj.length === 0) {
      return true;
    } else {
      return false;
    }
  }
}

// Allows us to convert prop names i.e. `fontBold` into the CSS equivalent
// Mainly used for our custom components like `EText` and `EBox`
export function generateCustomComponentClassNames(props) {
  return Object.keys(props)
    .map((prop) => {
      if (!props[prop]) return "";

      const className = prop.replace(
        /([A-Z])/g,
        (match) => `-${match.toLowerCase()}`
      );
      return `${className}`;
    })
    .join(" ");
}
export const stripHtml = (html) => {
  return (
    html
      // Replace <br> and block-level tags with a space or newline
      .replace(/<\/?(p|div|br)[^>]*>/gi, " ")
      // Strip out any other HTML tags
      .replace(/<\/?[^>]+(>|$)/g, "")
      // Normalize whitespace
      .replace(/\s+/g, " ")
      .trim()
  );
};
