import { Form, Input } from "antd";
import React, { useEffect } from "react";

const YoutubeForm = ({
  form,
  setForm,
  setIsDisabled,
  visionItem,
  handleSubmit,
}) => {
  const handleValuesChange = (changedValues) => {
    const youtube_url = changedValues.youtube_url;
    setIsDisabled(!youtube_url);
  };

  useEffect(() => {
    if (visionItem) {
      form.setFieldsValue({
        youtube_url: visionItem.youtube_url,
      });
    } else {
      form.setFieldsValue({
        youtube_url: "",
      });
    }
  }, [visionItem, form]);

  return (
    <Form
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleSubmit();
        }
      }}
      form={form}
      layout="vertical"
      name="form_in_modal"
      initialValues={{
        youtube_url: visionItem?.youtube_url,
      }}
      onValuesChange={handleValuesChange}
    >
      <Form.Item
        name="youtube_url"
        label="Youtube URL"
        rules={[
          {
            required: true,
            message: "Please enter a youtube URL",
          },
        ]}
      >
        <Input placeholder={"https://www.youtube.com/watch?v="} />
      </Form.Item>
    </Form>
  );
};

export default YoutubeForm;
