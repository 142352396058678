import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useToast = () => ({
  toastError: (msg = "Something went wrong, could you please try again?") =>
    toast(msg, { type: "error" }),
  toastSuccess: (msg) => toast(msg, { type: "success" }),
  toastInfo: (msg) => toast(msg, { type: "info" }),
});
export default useToast;
