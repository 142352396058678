import { Form, Upload, Button } from "antd";
import React, { useState, useEffect } from "react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { DeleteOutlined } from "@ant-design/icons"; // Ant Design's delete icon

const { Dragger } = Upload;

const ImageForm = ({
  form,
  setForm,
  setIsDisabled,
  visionItem,
  handleSubmit,
}) => {
  const [fileList, setFileList] = useState([]);
  const [existingImage, setExistingImage] = useState(visionItem?.image || null);

  useEffect(() => {
    if (existingImage) {
      setIsDisabled(false);
    }
  }, [existingImage, setIsDisabled]);

  useEffect(() => {
    setExistingImage(visionItem?.image || null);
  }, [visionItem, form]);

  const handleValuesChange = (changedValues) => {
    const image = changedValues.image;
    setIsDisabled(!image || image.length === 0);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const beforeUpload = (file) => {
    setFileList([file]); // Keep only the most recent file
    setExistingImage(null); // Remove the existing image when a new one is selected
    return false; // Prevent automatic upload
  };

  const handleRemoveExistingImage = () => {
    setExistingImage(null); // Remove the existing image
    setIsDisabled(true); // Disable the submit button until a new image is uploaded
  };

  return (
    <Form
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleSubmit();
        }
      }}
      form={form}
      layout="vertical"
      name="form_in_modal"
      initialValues={{
        image: null,
      }}
      onValuesChange={handleValuesChange}
    >
      {existingImage && (
        <div
          style={{
            marginBottom: "16px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={existingImage}
            alt="Existing Vision Item"
            style={{
              maxWidth: "100%",
              maxHeight: "300px",
              marginBottom: "8px",
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={handleRemoveExistingImage}
            danger
          >
            Remove Existing Image
          </Button>
        </div>
      )}

      <Form.Item
        name="image"
        label="Upload New Image"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: !existingImage, // Only required if there isn't an existing image
            message: "Please upload an image",
          },
        ]}
      >
        <Dragger
          name="image"
          listType="picture"
          beforeUpload={beforeUpload}
          fileList={fileList}
          onRemove={() => {
            setFileList([]);
            setIsDisabled(true);
          }}
          maxCount={1}
        >
          <p className="ant-upload-drag-icon">
            <ArrowUpTrayIcon height="32" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
      </Form.Item>
    </Form>
  );
};

export default ImageForm;
