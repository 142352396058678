import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosI from "helpers/axiosInstance";

const initialState = {
  standardQuotes: {},
  starredQuotes: {},
  quoteCategories: {},
  fetchingInitialData: false,
  activeCustomQuotesCategoryId: null,
  customQuotes: {},
  customQuotesCategories: {},
};

export const getData = createAsyncThunk("quotes/getData", async (thunkAPI) => {
  const response = await axiosI.get("/quotes.json");
  return response.data;
});

export const toggleQuoteStar = createAsyncThunk(
  "quotes/toggleQuoteStar",
  async (quoteId, thunkAPI) => {
    const response = await axiosI.post(`/quotes/toggle_star/${quoteId}`);
    return response.data;
  }
);

export const createCustomQuotesCategory = createAsyncThunk(
  "quotes/createCustomQuotesCategory",
  async (data, thunkAPI) => {
    try {
      const response = await axiosI.post("/quotes", { ...data });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateCustomQuotesCategory = createAsyncThunk(
  "quotes/updateCustomQuotesCategory",
  async (data, thunkAPI) => {
    try {
      const response = await axiosI.patch(`/quotes/${data.id}`, { ...data });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteCustomQuotesCategory = createAsyncThunk(
  "quotes/deleteCustomQuotesCategory",
  async (id, thunkAPI) => {
    const response = await axiosI.delete(`/quotes/${id}`);
    return response.data;
  }
);

export const createCustomQuote = createAsyncThunk(
  "quotes/createCustomQuote",
  async (data, thunkAPI) => {
    try {
      const response = await axiosI.post("/custom_quotes", { ...data });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateCustomQuote = createAsyncThunk(
  "quotes/updateCustomQuote",
  async (data, thunkAPI) => {
    try {
      const response = await axiosI.patch(`/custom_quotes/${data.id}`, {
        ...data,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteCustomQuote = createAsyncThunk(
  "quotes/deleteCustomQuote",
  async (id, thunkAPI) => {
    const response = await axiosI.delete(`/custom_quotes/${id}`);
    return response.data;
  }
);

export const quotesSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    updateActiveEntryId: (state, action) => {
      state.activeEntryId = action.payload;
    },
    updateActiveCustomQuotesCategoryId: (state, action) => {
      state.activeCustomQuotesCategoryId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      const {
        starred_quotes,
        standard_quotes,
        quote_categories,
        custom_quotes,
        custom_quotes_categories,
        active_custom_quotes_category_id,
      } = action.payload;

      state.starredQuotes = starred_quotes;
      state.standardQuotes = standard_quotes;
      state.quoteCategories = quote_categories;
      state.customQuotes = custom_quotes;
      state.customQuotesCategories = custom_quotes_categories;
      state.activeCustomQuotesCategoryId = active_custom_quotes_category_id;

      state.fetchingInitialData = false;
    });
    builder.addCase(getData.pending, (state, action) => {
      state.fetchingInitialData = true;
    });
    builder.addCase(getData.rejected, (state, action) => {
      state.fetchingInitialData = false;
    });
    builder.addCase(toggleQuoteStar.fulfilled, (state, action) => {
      const { starred_quote } = action.payload;

      if (starred_quote) {
        state.starredQuotes[starred_quote.standard_quote_id] = starred_quote;
      } else {
        delete state.starredQuotes[action.meta.arg];
      }
    });
    builder.addCase(createCustomQuotesCategory.fulfilled, (state, action) => {
      const { custom_quotes_category } = action.payload;
      state.customQuotesCategories[custom_quotes_category.id] =
        custom_quotes_category;
      state.activeCustomQuotesCategoryId = custom_quotes_category.id;
    });
    builder.addCase(updateCustomQuotesCategory.fulfilled, (state, action) => {
      const { custom_quotes_category } = action.payload;
      state.customQuotesCategories[custom_quotes_category.id] =
        custom_quotes_category;
    });
    builder.addCase(deleteCustomQuotesCategory.fulfilled, (state, action) => {
      delete state.customQuotesCategories[action.meta.arg];
      state.activeCustomQuotesCategoryId =
        action.payload.latest_custom_quotes_category_id;
    });
    builder.addCase(createCustomQuote.fulfilled, (state, action) => {
      const { custom_quote } = action.payload;
      state.customQuotes[custom_quote.id] = custom_quote;
    });
    builder.addCase(updateCustomQuote.fulfilled, (state, action) => {
      const { custom_quote } = action.payload;
      state.customQuotes[custom_quote.id] = custom_quote;
    });
    builder.addCase(deleteCustomQuote.fulfilled, (state, action) => {
      delete state.customQuotes[action.meta.arg];
    });
  },
});

// Action creators are generated for each case reducer function
export const { updateActiveCustomQuotesCategoryId } = quotesSlice.actions;

export default quotesSlice.reducer;
