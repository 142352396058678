import { createSelector } from "reselect";

export const selectAllStarredQuotes = (state) => state.quotes.starredQuotes;
export const selectCustomQuotesCategories = (state) =>
  state.quotes.customQuotesCategories;

export const selectCustomQuotes = (state) => state.quotes.customQuotes;

export const selectActiveCustomQuotesCategoryId = (state) =>
  state.quotes.activeCustomQuotesCategoryId;

export const selectQuoteIsStarred = createSelector(
  selectAllStarredQuotes,
  (state, props) => props.quoteId,
  (starredQuotes, quoteId) => {
    return !!starredQuotes[quoteId];
  }
);

export const selectCustomQuotesCategoriesArray = createSelector(
  selectCustomQuotesCategories,
  (quotesCategories) => {
    return Object.values(quotesCategories).sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at) // sort by created_at in ascending order
    );
  }
);

export const selectCustomQuotesArray = createSelector(
  selectCustomQuotes,
  (customQuotes) => {
    return Object.values(customQuotes);
  }
);

export const selectCustomQuotesForActiveCategory = createSelector(
  [selectCustomQuotesArray, selectActiveCustomQuotesCategoryId],
  (customQuotes, activeCustomQuotesCategoryId) => {
    return customQuotes
      .filter(
        (item) =>
          item.custom_quotes_category_id === activeCustomQuotesCategoryId
      ) // Filter by active board ID
      .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)); // Sort by updated_at in descending order
  }
);
