import React from "react";
import "components/LBox/LBox.css";
import "components/spacing.css";
import "components/colors.css";
import "components/font.css";
import { generateCustomComponentClassNames } from "helpers/generalHelpers";

const LBox = ({
  children,
  style,
  id,
  className,
  onMouseEnter,
  onMouseLeave,
  onClick,
  ...props
}) => {
  const classNames = generateCustomComponentClassNames(props);

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={`LBox ${classNames} ${className}`}
      style={style}
      id={id}
    >
      {children}
    </div>
  );
};

export default LBox;
