import { createSelector } from "reselect";

export const selectVisionBoards = (state) => state.vision.visionBoards;
export const selectVisionItems = (state) => state.vision.visionItems;
export const selectActiveVisionBoardId = (state) =>
  state.vision.activeVisionBoardId;

export const selectVisionBoardsArray = createSelector(
  selectVisionBoards,
  (visionBoards) => {
    return Object.values(visionBoards).sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at) // sort by created_at in ascending order
    );
  }
);

export const selectVisionItemsArray = createSelector(
  selectVisionItems,
  (visionItems) => {
    return Object.values(visionItems);
  }
);

export const selectVisionItemsForActiveBoard = createSelector(
  [selectVisionItemsArray, selectActiveVisionBoardId],
  (visionItems, activeVisionBoardId) => {
    return visionItems
      .filter((item) => item.vision_board_id === activeVisionBoardId) // Filter by active board ID
      .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)); // Sort by updated_at in descending order
  }
);
