import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "slices/userSlice";
import useToast from "hooks/useToast";
import { isEmpty } from "lodash";

const GeneralTab = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const user = useSelector((state) => state.user.user);
  const { toastError, toastSuccess } = useToast();
  const [isTouched, setIsTouched] = useState();
  const handleSubmit = (values) => {
    const payload = {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone,
    };

    if (!isEmpty(values.password)) {
      payload.password = values.password;
    }

    dispatch(updateUser(payload))
      .unwrap()
      .then(() => {
        toastSuccess("User profile updated successfully");
      })
      .catch((error) => {
        console.log("error", error);
        toastError(error.error);
      });
  };

  return (
    <div>
      <Form
        form={form}
        name="basic"
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          phone: user.phone,
          password: "",
        }}
        onFinish={handleSubmit}
        autoComplete="off"
        validateTrigger={"onSubmit"}
        onFieldsChange={() => {
          setIsTouched(true);
        }}
      >
        <Form.Item
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please enter your first name",
            },
          ]}
        >
          <Input placeholder={"First Name"} />
        </Form.Item>

        <Form.Item name="last_name">
          <Input placeholder={"Last Name"} />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter an e-mail",
              type: "email",
            },
          ]}
        >
          <Input placeholder={"Email Address"} />
        </Form.Item>

        <Form.Item
          name="phone"
          rules={[
            {
              type: "phone",
            },
          ]}
        >
          <Input placeholder={"Phone"} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              min: 8,
              message: "Password has to be a minimum of 8 characters long",
            },
          ]}
        >
          <Input.Password placeholder={"New Password"} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            disabled={!isTouched}
          >
            {"Update"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GeneralTab;
