import { Form, Input, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import useToast from "hooks/useToast";
import LErrorMessages from "components/LErrorMessages/LErrorMessages";
import {
  createCustomQuotesCategory,
  updateCustomQuotesCategory,
} from "quotes/quotesSlice";

const CreateOrUpdateCustomQuotesCategoryModal = ({
  isCreate,
  customQuotesCategory,
}) => {
  const { toastError, toastSuccess } = useToast();
  const [errors, setErrors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const createOrUpdateCustomQuotesCategoryModalIsOpen = useSelector(
    (state) => state.modals.createOrUpdateCustomQuotesCategoryModalIsOpen
  );
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    if (customQuotesCategory) {
      form.setFieldsValue({
        name: customQuotesCategory.name,
      });
    } else {
      form.setFieldsValue({
        name: "",
      });
    }
  }, [customQuotesCategory, form]);

  const handleCloseModal = () => {
    form.resetFields();
    setErrors([]);
    setIsDisabled(true);
    dispatch(
      toggleModal({
        modalName: MODAL_NAMES.CREATE_OR_UPDATE_CUSTOM_QUOTES_CATEGORY,
      })
    );
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const action = isCreate
        ? createCustomQuotesCategory
        : updateCustomQuotesCategory;
      const payload = isCreate
        ? { name: values.name }
        : { id: customQuotesCategory.id, name: values.name };

      dispatch(action(payload))
        .unwrap()
        .then(() => {
          handleCloseModal();
          toastSuccess(
            isCreate
              ? "Category added successfully! 🎉"
              : "Category updated successfully! 🎉"
          );
        })
        .catch(({ errors }) => {
          setErrors(errors);
        });
    });
  };

  const handleValuesChange = (changedValues) => {
    const name = changedValues.name;
    setIsDisabled(!name);
  };

  return (
    <Modal
      open={createOrUpdateCustomQuotesCategoryModalIsOpen}
      title={isCreate ? "Create Quotes Category" : "Update Quotes Category"}
      okText={isCreate ? "Create" : "Update"}
      cancelText="Cancel"
      onCancel={handleCloseModal}
      onOk={handleSubmit}
      okButtonProps={{ disabled: isDisabled }}
    >
      <LErrorMessages errors={errors} />
      <Form
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        }}
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          name: customQuotesCategory?.name,
        }}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter a name for your quotes category",
            },
          ]}
        >
          <Input placeholder={"Motivation"} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateOrUpdateCustomQuotesCategoryModal;
