import React from "react";
import { isEmpty } from "lodash";
import LText from "components/LText/LText";
import LBox from "components/LBox/LBox";

const LErrorMessages = ({ errors }) => {
  if (isEmpty(errors)) {
    return null;
  }

  return (
    <LBox mb8>
      <LText mt0 mb0>
        Please correct the following errors:
      </LText>
      {errors.map((error) => {
        return (
          <LBox primaryColor>
            <LText mt0 mb0 fs12 accentColor>
              - {error}
            </LText>
          </LBox>
        );
      })}
    </LBox>
  );
};
export default LErrorMessages;
