import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  Row,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import { checkSubscriptionStatus, updatePremium } from "slices/billingSlice";
import { toggleModal, MODAL_NAMES } from "slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import useInterval from "hooks/useInterval";
import { useNavigate } from "react-router-dom";

const { Title, Paragraph } = Typography;

const UpgradeModalSuccess = () => {
  const billingSuccessModalIsOpen = useSelector(
    (state) => state.modals.billingSuccessModalIsOpen
  );
  const [isSubscribed, setIsSubscribed] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkAndUpdateSubscribed = () => {
    dispatch(checkSubscriptionStatus()).then((res) => {
      if (res.payload.status === "active") {
        setIsSubscribed(true);
        dispatch(updatePremium(true));
        navigate("/");
      }
    });
  };

  useEffect(() => {
    if (window.location.href.includes("billing/success")) {
      dispatch(toggleModal({ modalName: MODAL_NAMES.BILLING_SUCCESS }));
      checkAndUpdateSubscribed();
    }
  }, []);

  useInterval(() => {
    if (billingSuccessModalIsOpen && !isSubscribed) {
      checkAndUpdateSubscribed();
    }
  }, 1000);

  const handleClose = () => {
    dispatch(toggleModal({ modalName: MODAL_NAMES.BILLING_SUCCESS }));
  };

  return (
    <Modal
      open={billingSuccessModalIsOpen}
      onClose={handleClose}
      onCancel={handleClose}
      footer={
        <Button key="cancel" onClick={handleClose}>
          Close
        </Button>
      }
    >
      <Row justify="space-between" align="middle">
        <Col>
          {!isSubscribed && (
            <>
              <Title level={3}>Activating LumifyHub Pro!</Title>
              <Paragraph style={{ marginTop: 10 }}>
                Please wait, we're activating all your awesome new Pro features!
                <Spin style={{ marginTop: 10 }} />
              </Paragraph>
            </>
          )}
          {isSubscribed && (
            <>
              <Title level={3}>Thank You!</Title>
              <Paragraph style={{ marginTop: 10 }}>
                Thanks so much for upgrading to LumifyHub Pro! Here are all the
                features you've now unlocked and can start using immediately:
              </Paragraph>
              <b>Pro Features:</b>
              <ul style={{ marginLeft: 20 }}>
                <li>Unlimited Image Vision Items</li>
                <li>Unlimited Vision Boards</li>
                <li>Unlimited Gratitude Entries</li>
                <li>Unlimited Journal Entries</li>
                <li>Unlimited Custom Quotes</li>
                <li>Email Notifications</li>
              </ul>
              <Paragraph style={{ marginTop: 10 }}>
                Please let us know if you have any questions or feedback. We're
                always here to help! Contact us directly at{" "}
                <b>hello@lumifyhub.io</b>
              </Paragraph>
            </>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default UpgradeModalSuccess;
