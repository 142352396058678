import React, { useEffect } from "react";
import { Col, Modal, Row, Typography } from "antd";
import { toggleModal, MODAL_NAMES } from "slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";

const { Title, Paragraph, Text } = Typography;

const InfoModal = ({ infoText, infoTitle, content }) => {
  const infoModalIsOpen = useSelector((state) => state.modals.infoModalIsOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(toggleModal({ modalName: MODAL_NAMES.INFO }));
  };

  return (
    <Modal
      open={infoModalIsOpen}
      onClose={handleClose}
      onCancel={handleClose}
      okText="Got it, thanks!"
      onOk={handleClose}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3}>{infoTitle}</Title>
          {!content && (
            <Paragraph style={{ marginTop: 10 }}>{infoText}</Paragraph>
          )}
          {content}
        </Col>
      </Row>
    </Modal>
  );
};

export default InfoModal;
