import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosI from "helpers/axiosInstance";
import { deleteGratitudeEntry } from "gratitude/gratitudeSlice";

const initialState = {
  visionItems: {},
  visionBoards: {},
  activeVisionBoardId: null,
  fetchingInitialData: true,
};

export const getVisionData = createAsyncThunk(
  "vision/getVisionData",
  async (thunkAPI) => {
    const response = await axiosI.get("/vision.json");
    return response.data;
  }
);

export const createVisionItem = createAsyncThunk(
  "vision/createVisionItem",
  async (formData, thunkAPI) => {
    try {
      const response = await axiosI.post("/vision_item", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        timeout: 10000,
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateVisionItem = createAsyncThunk(
  "vision/updateVisionItem",
  async (formData, thunkAPI) => {
    try {
      const response = await axiosI.patch(
        `/vision_item/${formData.get("id")}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          timeout: 10000,
        }
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteVisionItem = createAsyncThunk(
  "vision/deleteVisionItem",
  async (id, thunkAPI) => {
    const response = await axiosI.delete(`/vision_item/${id}`);
    return response.data;
  }
);

export const createVisionBoard = createAsyncThunk(
  "vision/createVisionBoard",
  async (data, thunkAPI) => {
    try {
      const response = await axiosI.post("/vision", { ...data });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateVisionBoard = createAsyncThunk(
  "vision/updateVisionBoard",
  async (data, thunkAPI) => {
    try {
      const response = await axiosI.patch(`/vision/${data.id}`, { ...data });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteVisionBoard = createAsyncThunk(
  "vision/deleteVisionBoard",
  async (id, thunkAPI) => {
    const response = await axiosI.delete(`/vision/${id}`);
    return response.data;
  }
);

export const visionSlice = createSlice({
  name: "vision",
  initialState,
  reducers: {
    updateActiveVisionBoardId: (state, action) => {
      state.activeVisionBoardId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVisionData.fulfilled, (state, action) => {
      state.visionItems = action.payload.vision_items;
      state.visionBoards = action.payload.vision_boards;
      state.activeVisionBoardId = action.payload.active_vision_board_id;
      state.fetchingInitialData = false;
    });
    builder.addCase(getVisionData.pending, (state, action) => {
      state.fetchingInitialData = true;
    });
    builder.addCase(getVisionData.rejected, (state, action) => {
      state.fetchingInitialData = false;
    });
    builder.addCase(createVisionItem.fulfilled, (state, action) => {
      const { vision_item } = action.payload;
      state.visionItems[vision_item.id] = vision_item;
    });
    builder.addCase(updateVisionItem.fulfilled, (state, action) => {
      const { vision_item } = action.payload;
      state.visionItems[vision_item.id] = vision_item;
    });
    builder.addCase(createVisionBoard.fulfilled, (state, action) => {
      const { vision_board } = action.payload;
      state.visionBoards[vision_board.id] = vision_board;
      state.activeVisionBoardId = vision_board.id;
    });
    builder.addCase(updateVisionBoard.fulfilled, (state, action) => {
      const { vision_board } = action.payload;
      state.visionBoards[vision_board.id] = vision_board;
    });
    builder.addCase(deleteVisionBoard.fulfilled, (state, action) => {
      delete state.visionBoards[action.meta.arg];
      state.activeVisionBoardId = action.payload.latest_vision_board_id;
    });
    builder.addCase(deleteVisionItem.fulfilled, (state, action) => {
      delete state.visionItems[action.meta.arg];
    });
  },
});

// Action creators are generated for each case reducer function
export const { updateActiveVisionBoardId } = visionSlice.actions;

export default visionSlice.reducer;
