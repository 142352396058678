import React, { useEffect } from "react";
import { Col, Modal, Row, Typography } from "antd";
import { toggleModal, MODAL_NAMES } from "slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "slices/userSlice";

const { Title, Paragraph, Text } = Typography;

const OnTrialModal = () => {
  const currentUser = useSelector((state) => state.user.user);
  const onTrialModalIsOpen = useSelector(
    (state) => state.modals.onTrialModalIsOpen
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      updateUser({ id: currentUser.id, meta: { seen_on_trial_modal: true } })
    );
    dispatch(toggleModal({ modalName: MODAL_NAMES.ON_TRIAL }));
  };

  useEffect(() => {
    if (currentUser && !currentUser.meta.seen_on_trial_modal) {
      dispatch(toggleModal({ modalName: MODAL_NAMES.ON_TRIAL }));
    }
  }, [currentUser]);

  if (!currentUser || currentUser.meta.seen_on_trial_modal) {
    return null;
  }

  return (
    <Modal
      open={true}
      onClose={handleClose}
      onCancel={handleClose}
      okText="Got it, thanks!"
      onOk={handleClose}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3}>Welcome!</Title>
          <Paragraph style={{ marginTop: 10 }}>
            Hi, thanks for joining LumifyHub. We've put you on a free trial for
            <b> four weeks</b>. Enjoy the unlimited features! 🎉
          </Paragraph>
        </Col>
      </Row>
    </Modal>
  );
};

export default OnTrialModal;
