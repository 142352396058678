import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const MODAL_NAMES = {
  ACCOUNT: "account",
  COMMAND_HUB: "commandHub",
  BILLING_UPGRADE: "billingUpgrade",
  BILLING_SUCCESS: "billingSuccess",
  CREATE_NOTEBOOK: "createNotebook",
  CREATE_OR_UPDATE_VISION_ITEM: "createOrUpdateVisionItem",
  CREATE_OR_UPDATE_VISION_BOARD: "createOrUpdateVisionBoard",
  UPDATE_GRATITUDE_ENTRY: "updateGratitudeEntry",
  PREMIUM_FEATURE: "premiumFeature",
  CREATE_OR_UPDATE_CUSTOM_QUOTES_CATEGORY: "createOrUpdateCustomQuotesCategory",
  CREATE_OR_UPDATE_CUSTOM_QUOTE: "createOrUpdateCustomQuote",
  ON_TRIAL: "onTrial",
  INFO: "info",
  CREATE_OR_UPDATE_NOTIFICATION_SETTING: "createOrUpdateNotificationSetting",
};

const initialState = {
  accountModalIsOpen: false,
  commandHubModalIsOpen: false,
  billingUpgradeModalIsOpen: false,
  billingSuccessModalIsOpen: false,
  createNotebookModalIsOpen: false,
  createVisionItemModalIsOpen: false,
  createOrUpdateVisionBoardModalIsOpen: false,
  updateGratitudeEntryModalIsOpen: false,
  premiumFeatureModalIsOpen: false,
  createOrUpdateCustomQuotesCategoryModalIsOpen: false,
  createOrUpdateCustomQuoteModalIsOpen: false,
  onTrialModalIsOpen: false,
  infoModalIsOpen: false,
  createOrUpdateNotificationSettingModalIsOpen: false,
  modalProps: {},
};

export const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    toggleModal(state, action) {
      const { modalName, modalProps } = action.payload;
      state[`${modalName}ModalIsOpen`] = !state[`${modalName}ModalIsOpen`];
      state.modalProps = modalProps || {};
    },
  },
  extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
export const { toggleModal } = modalSlice.actions;

export default modalSlice.reducer;
