import { Form, Input, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import { createVisionBoard, updateVisionBoard } from "vision/visionSlice";
import useToast from "hooks/useToast";
import LErrorMessages from "components/LErrorMessages/LErrorMessages";

const CreateOrUpdateVisionBoardModal = ({ isCreate, visionBoard }) => {
  const { toastError, toastSuccess } = useToast();
  const [errors, setErrors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const createOrUpdateVisionBoardModalIsOpen = useSelector(
    (state) => state.modals.createOrUpdateVisionBoardModalIsOpen
  );
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    if (visionBoard) {
      form.setFieldsValue({
        name: visionBoard.name,
      });
    } else {
      form.setFieldsValue({
        name: "",
      });
    }
  }, [visionBoard, form]);

  const handleCloseModal = () => {
    form.resetFields();
    setErrors([]);
    setIsDisabled(true);
    dispatch(
      toggleModal({ modalName: MODAL_NAMES.CREATE_OR_UPDATE_VISION_BOARD })
    );
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const action = isCreate ? createVisionBoard : updateVisionBoard;
      const payload = isCreate
        ? { name: values.name }
        : { id: visionBoard.id, name: values.name };

      dispatch(action(payload))
        .unwrap()
        .then(() => {
          handleCloseModal();
          toastSuccess(
            isCreate
              ? "Vision board added successfully! 🎉"
              : "Vision board updated successfully! 🎉"
          );
        })
        .catch(({ errors }) => {
          setErrors(errors);
        });
    });
  };

  const handleValuesChange = (changedValues) => {
    const name = changedValues.name;
    setIsDisabled(!name);
  };

  return (
    <Modal
      open={createOrUpdateVisionBoardModalIsOpen}
      title={isCreate ? "Create Vision Board" : "Update Vision Board"}
      okText={isCreate ? "Create" : "Update"}
      cancelText="Cancel"
      onCancel={handleCloseModal}
      onOk={handleSubmit}
      okButtonProps={{ disabled: isDisabled }}
    >
      <LErrorMessages errors={errors} />
      <Form
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        }}
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          name: visionBoard?.name,
        }}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter a name for your vision board",
            },
          ]}
        >
          <Input placeholder={"Something you're working towards..."} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateOrUpdateVisionBoardModal;
