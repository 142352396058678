import { Form, Modal, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import useToast from "hooks/useToast";
import LErrorMessages from "components/LErrorMessages/LErrorMessages";
import {
  createNotificationSetting,
  updateNotificationSetting,
} from "slices/notificationsSlice";

const CreateOrUpdateNotificationSettingModal = ({
  isCreate,
  notificationSetting,
  ownerType,
  ownerId,
}) => {
  const convertNotificationIsEnabledToStr = (is_enabled) => {
    return is_enabled ? "true" : "false";
  };

  const initialFormValues = {
    medium: "email",
    frequency: "daily",
    notification_time: "09:00",
    is_enabled: "true",
  };

  const initialFormValuesWithNotificationSetting = {
    medium: notificationSetting?.medium,
    frequency: notificationSetting?.frequency,
    notification_time: notificationSetting?.notification_time,
    is_enabled: convertNotificationIsEnabledToStr(
      notificationSetting?.is_enabled
    ),
  };

  const { toastError, toastSuccess } = useToast();
  const [errors, setErrors] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState(""); // New state for the dynamic message
  const createOrUpdateNotificationSettingModalIsOpen = useSelector(
    (state) => state.modals.createOrUpdateNotificationSettingModalIsOpen
  );
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const timeOptions = [
    { display: "12AM", value: "00:00" },
    { display: "1AM", value: "01:00" },
    { display: "2AM", value: "02:00" },
    { display: "3AM", value: "03:00" },
    { display: "4AM", value: "04:00" },
    { display: "5AM", value: "05:00" },
    { display: "6AM", value: "06:00" },
    { display: "7AM", value: "07:00" },
    { display: "8AM", value: "08:00" },
    { display: "9AM", value: "09:00" },
    { display: "10AM", value: "10:00" },
    { display: "11AM", value: "11:00" },
    { display: "12PM", value: "12:00" },
    { display: "1PM", value: "13:00" },
    { display: "2PM", value: "14:00" },
    { display: "3PM", value: "15:00" },
    { display: "4PM", value: "16:00" },
    { display: "5PM", value: "17:00" },
    { display: "6PM", value: "18:00" },
    { display: "7PM", value: "19:00" },
    { display: "8PM", value: "20:00" },
    { display: "9PM", value: "21:00" },
    { display: "10PM", value: "22:00" },
    { display: "11PM", value: "23:00" },
  ];

  useEffect(() => {
    if (notificationSetting) {
      form.setFieldsValue({
        medium: notificationSetting.medium,
        frequency: notificationSetting.frequency,
        notification_time: notificationSetting.notification_time,
        is_enabled: notificationSetting.is_enabled ? "true" : "false",
      });
    } else {
      form.setFieldsValue(initialFormValues);
      updateNotificationMessage(
        initialFormValues.is_enabled,
        initialFormValues.frequency,
        initialFormValues.notification_time
      );
    }
  }, [notificationSetting, form]);

  useEffect(() => {
    const values = form.getFieldsValue();
    updateNotificationMessage(
      values.is_enabled,
      values.frequency,
      values.notification_time
    );
  }, [form, createOrUpdateNotificationSettingModalIsOpen]);

  const convertOwnerTypeToDisplayMessage = (ownerType) => {
    if (ownerType === "VisionBoard") {
      return "vision item";
    } else if (ownerType === "CustomQuotesCategory") {
      return "custom quote";
    }
  };

  const updateNotificationMessage = (
    isEnabled,
    frequency,
    notification_time
  ) => {
    if (isEnabled === "false") {
      setNotificationMessage("This notification is currently disabled");
    } else if (frequency && notification_time) {
      const displayTime = timeOptions.find(
        (option) => option.value === notification_time
      )?.display;
      setNotificationMessage(
        `You will receive an email ${frequency} at ${displayTime} with a randomly selected ${convertOwnerTypeToDisplayMessage(
          ownerType
        )}.`
      );
    } else {
      setNotificationMessage("");
    }
  };

  const handleCloseModal = () => {
    form.setFieldsValue(initialFormValues);

    setErrors([]);
    setNotificationMessage(""); // Clear message on modal close
    dispatch(
      toggleModal({
        modalName: MODAL_NAMES.CREATE_OR_UPDATE_NOTIFICATION_SETTING,
      })
    );
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const action = isCreate
        ? createNotificationSetting
        : updateNotificationSetting;
      const payload = {
        medium: values.medium,
        frequency: values.frequency,
        notification_time: values.notification_time, // Use the value which is in HH:mm format
        is_enabled: values.is_enabled === "true", // Convert to boolean
        owner_id: ownerId,
        owner_type: ownerType,
      };

      if (!isCreate) {
        payload.id = notificationSetting.id;
      }

      dispatch(action(payload))
        .unwrap()
        .then(() => {
          handleCloseModal();
          toastSuccess(
            isCreate
              ? "Notification setting added successfully! 🎉"
              : "Notification setting updated successfully! 🎉"
          );
        })
        .catch(({ errors }) => {
          setErrors(errors);
        });
    });
  };

  const handleValuesChange = () => {
    const values = form.getFieldsValue();
    const { medium, frequency, notification_time, is_enabled } = values;

    // setIsDisabled(!medium || !frequency || !notification_time || !is_enabled);
    updateNotificationMessage(is_enabled, frequency, notification_time);
  };

  return (
    <Modal
      open={createOrUpdateNotificationSettingModalIsOpen}
      title={
        isCreate ? "Create Notification Setting" : "Update Notification Setting"
      }
      okText={isCreate ? "Create" : "Update"}
      cancelText="Cancel"
      onCancel={handleCloseModal}
      onOk={handleSubmit}
    >
      <LErrorMessages errors={errors} />
      {notificationMessage && (
        <p style={{ marginBottom: "16px", color: "var(--default-font-color)" }}>
          {notificationMessage}
        </p>
      )}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={
          notificationSetting
            ? initialFormValuesWithNotificationSetting
            : initialFormValues
        }
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name="medium"
          label="Type"
          rules={[
            {
              required: true,
              message: "Please select a type",
            },
          ]}
        >
          <Select placeholder="Select type">
            <Select.Option value="email">Email</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="frequency"
          label="Frequency"
          rules={[
            {
              required: true,
              message: "Please select a notification frequency",
            },
          ]}
        >
          <Select>
            <Select.Option value="daily">Everyday</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="notification_time"
          label="Time"
          rules={[
            {
              required: true,
              message: "Please select a notification time",
            },
          ]}
        >
          <Select>
            {timeOptions.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.display}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* New Field for Enabling or Disabling Notifications */}
        <Form.Item
          name="is_enabled"
          label="Status"
          rules={[
            {
              required: true,
              message: "Please select whether notifications are enabled",
            },
          ]}
        >
          <Select placeholder="status">
            <Select.Option value="true">Enabled</Select.Option>
            <Select.Option value="false">Disabled</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateOrUpdateNotificationSettingModal;
