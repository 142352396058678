import UpgradeModal from "modals/UpgradeModal/UpgradeModal";
import UpgradeModalSuccess from "modals/UpgradeModalSuccess/UpgradeModalSuccess";
import React from "react";
import MyAccountModal from "modals/MyAccountModal/MyAccountModal";
import CreateOrUpdateVisionItemModal from "modals/CreateOrUpdateVisionItemModal/CreateOrUpdateVisionItemModal";
import CreateOrUpdateVisionBoardModal from "modals/CreateOrUpdateVisionBoardModal/CreateOrUpdateVisionBoardModal";
import { useSelector } from "react-redux";
import UpdateGratitudeEntryModal from "modals/UpdateGratitudeEntryModal/UpdateGratitudeEntryModal";
import PremiumFeatureModal from "modals/PremiumFeatureModal/PremiumFeatureModal";
import CreateOrUpdateCustomQuotesCategoryModal from "modals/CreateOrUpdateCustomQuotesCategoryModal/CreateOrUpdateCustomQuotesCategoryModal";
import CreateOrUpdateCustomQuoteModal from "modals/CreateOrUpdateCustomQuoteModal/CreateOrUpdateCustomQuoteModal";
import OnTrialModal from "modals/OnTrialModal/OnTrialModal";
import InfoModal from "modals/InfoModal/InfoModal";
import CreateOrUpdateNotificationSettingModal from "modals/CreateOrUpdateNotificationSettingsModal/CreateOrUpdateNotificationsSettingModal";

const GlobalComponents = () => {
  const modalProps = useSelector((state) => state.modals.modalProps);
  return (
    <>
      <UpgradeModal />
      <UpgradeModalSuccess />
      {/*<CreateOrUpdateNotificationsSettingModal />*/}
      <MyAccountModal />
      {/*<CommandHubModal />*/}
      <CreateOrUpdateVisionItemModal {...modalProps} />
      <CreateOrUpdateVisionBoardModal {...modalProps} />
      <UpdateGratitudeEntryModal {...modalProps} />
      <PremiumFeatureModal {...modalProps} />
      <CreateOrUpdateCustomQuotesCategoryModal {...modalProps} />
      <CreateOrUpdateCustomQuoteModal {...modalProps} />
      <OnTrialModal />
      <InfoModal {...modalProps} />
      <CreateOrUpdateNotificationSettingModal {...modalProps} />
    </>
  );
};
export default GlobalComponents;
