import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Spin, Switch, Tooltip, Typography } from "antd";
import { createSessionCheckout } from "slices/billingSlice";
import { toggleModal, MODAL_NAMES } from "slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";

const { Title, Paragraph, Text } = Typography;

const PremiumFeatureModal = () => {
  const premiumFeatureModalIsOpen = useSelector(
    (state) => state.modals.premiumFeatureModalIsOpen
  );
  const dispatch = useDispatch();

  const handleOpenUpgradeModal = () => {
    dispatch(toggleModal({ modalName: MODAL_NAMES.BILLING_UPGRADE }));
  };

  const handleClose = () => {
    dispatch(toggleModal({ modalName: MODAL_NAMES.PREMIUM_FEATURE }));
  };

  return (
    <Modal
      open={premiumFeatureModalIsOpen}
      onClose={handleClose}
      onCancel={handleClose}
      okText="Unlock Premium Features"
      onOk={handleOpenUpgradeModal}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3}>Unlock Premium Feature</Title>
          <Paragraph style={{ marginTop: 10 }}>
            Hi, it looks like you've hit our limit for free users. Upgrade to
            LumifyHub Pro to unlock unlimited features!
          </Paragraph>
        </Col>
      </Row>
    </Modal>
  );
};

export default PremiumFeatureModal;
