import { Form, Input, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_NAMES, toggleModal } from "slices/modalSlice";
import useToast from "hooks/useToast";
import LErrorMessages from "components/LErrorMessages/LErrorMessages";
import { createCustomQuote, updateCustomQuote } from "quotes/quotesSlice";
import { selectActiveCustomQuotesCategoryId } from "quotes/selectors";

const { TextArea } = Input;

const CreateOrUpdateCustomQuoteModal = ({ isCreate, customQuote }) => {
  const activeCustomQuotesCategoryId = useSelector(
    selectActiveCustomQuotesCategoryId
  );
  const { toastError, toastSuccess } = useToast();
  const [errors, setErrors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const createOrUpdateCustomQuoteModalIsOpen = useSelector(
    (state) => state.modals.createOrUpdateCustomQuoteModalIsOpen
  );
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    if (customQuote) {
      form.setFieldsValue({
        author: customQuote.author,
        content: customQuote.content,
      });
    } else {
      form.setFieldsValue({
        author: "",
        content: "",
      });
    }
  }, [customQuote, form]);

  const handleCloseModal = () => {
    form.resetFields();
    setErrors([]);
    setIsDisabled(true);
    dispatch(
      toggleModal({
        modalName: MODAL_NAMES.CREATE_OR_UPDATE_CUSTOM_QUOTE,
      })
    );
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const action = isCreate ? createCustomQuote : updateCustomQuote;
      const payload = {
        content: values.content,
        author: values.author,
        custom_quotes_category_id: activeCustomQuotesCategoryId,
      };

      if (!isCreate) {
        payload.id = customQuote.id;
      }

      dispatch(action(payload))
        .unwrap()
        .then(() => {
          handleCloseModal();
          toastSuccess(
            isCreate
              ? "Quote added successfully! 🎉"
              : "Quote updated successfully! 🎉"
          );
        })
        .catch(({ errors }) => {
          setErrors(errors);
        });
    });
  };

  const handleValuesChange = () => {
    const values = form.getFieldsValue();
    const { author, content } = values;

    setIsDisabled(!author || !content);
  };

  return (
    <Modal
      open={createOrUpdateCustomQuoteModalIsOpen}
      title={isCreate ? "Create Quote" : "Update Quote"}
      okText={isCreate ? "Create" : "Update"}
      cancelText="Cancel"
      onCancel={handleCloseModal}
      onOk={handleSubmit}
      okButtonProps={{ disabled: isDisabled }}
    >
      <LErrorMessages errors={errors} />
      <Form
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSubmit();
          }
        }}
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          content: customQuote?.content,
          author: customQuote?.author,
        }}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name="content"
          label="Quote"
          rules={[
            {
              required: true,
              message: "Please enter a quote",
            },
          ]}
        >
          <TextArea
            rows={4}
            placeholder="To be or not to be..."
            maxLength={500}
          />
        </Form.Item>
        <Form.Item
          name="author"
          label="Author"
          rules={[
            {
              required: true,
              message: "Please enter a author",
            },
          ]}
        >
          <Input placeholder={"Harry Potter"} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateOrUpdateCustomQuoteModal;
